import * as React from "react";
import {
  Box,
  Typography,
  Link,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import Layout from "../../components/Layout";
import { MetaData } from "../../components/common/meta";
import { PageProps } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { StaticImage } from "gatsby-plugin-image";
import { Link as RouterLink } from "gatsby";
import ArrowComponent from "../../components/thePit/Arrow";

import pit from "../../images/xhack/pit.png";
import Arrow from "../../images/arrow-right.inline.svg";
import polygon from "../../images/polygon/polygon.svg";
import polygon2 from "../../images/polygon/polygon2.svg";
import city from "../../images/polygon/city.svg";
import city2 from "../../images/polygon/city2.svg";
import sideBrand from "../../images/lineBrand.svg";
import Location from "../../images/polygon/location.inline.svg";
import Calendar from "../../images/polygon/calendar.inline.svg";
import Twitter from "../../images/twitter.inline.svg";

const heading = {
  fontWeight: 600,
  fontFamily: ["Poppins", "Helvetica", "Arial"].join(","),
  fontSize: { xs: 32, md: 40 },
  lineHeight: 1.3,
  letterSpacing: "-0.01em",
};

const box = {
  background: "#051535",
  border: "1px solid #4B608D",
  padding: "20px 10px",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& p": {
    fontSize: 20,
    lineHeight: 1.2,
    fontFamily: ["Poppins", "Helvetica", "Arial"].join(","),
  },
};

const card = {
  background: "#0C1F47",
  border: "1px solid #4B608D",
  padding: 1.6,
  aspectRatio: "1/1",
  position: "relative",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "flex-start",
  overflow: "hidden",
  "& h6": {
    color: "#1CE7C2",
    mb: 0.5,
    position: "relative",
  },
  "& a": {
    position: "relative",
    display: "block",
  },
  "& p": {
    fontSize: 13,
    position: "relative",
    "& span": {
      display: "block",
      "&:not(:last-of-type):after": {
        content: "','",
      },
    },
  },
};

const box2 = {
  background: "#0C1F47",
  border: "1px solid #4B608D",

  textAlign: "center",
  display: "flex",
  alignItems: "center",
  px: { xs: 3, md: 5 },
  py: 1,
  minHeight: "90px",
  "& svg": {
    display: "block",
    flex: "0 0 auto",
    mr: { xs: 3, md: 5 },
  },
};

import kanav from "../../images/xhack/kanav.png";
import saurabh from "../../images/polygon/saurabh.png";
import sandeep from "../../images/polygon/sandeep.png";

import dalip from "../../images/polygon/dalip.png";
import matt from "../../images/polygon/matt.png";
import jia from "../../images/polygon/jia.png";
import jaynti from "../../images/polygon/jaynti.png";
import steph from "../../images/polygon/steph.png";
import deli from "../../images/polygon/deli.png";
import tn from "../../images/polygon/tn.png";
import zheng from "../../images/polygon/zheng.png";
import cindy from "../../images/polygon/cindy.jpg";
import julian from "../../images/polygon/julian.jpg";
import saeed from "../../images/polygon/saeed.jpg";

import aakash from "../../images/polygon/aakash.png";
import alan from "../../images/polygon/alan.png";
import kao from "../../images/polygon/alicia.png";
import aniket from "../../images/polygon/aniket.png";
import loh from "../../images/polygon/ben.png";
import schwartz from "../../images/polygon/david.png";
import frank from "../../images/polygon/frank.png";
import dizon from "../../images/polygon/gabby.png";
import gin from "../../images/polygon/gin.png";
import harsh from "../../images/polygon/harsh.png";
import kang from "../../images/polygon/kang.png";
import lennix from "../../images/polygon/lennix.png";
import mayur from "../../images/polygon/mayur.png";
import mudit from "../../images/polygon/mudit.png";
import nihar from "../../images/polygon/nihar.png";
import nikhil from "../../images/polygon/nikhil.png";
import paul from "../../images/polygon/paul.png";
import robert from "../../images/polygon/robert.png";
import sahil from "../../images/polygon/sahil.png";
import sanket from "../../images/polygon/sanket.png";
import shanav from "../../images/polygon/shanav.png";
import suji from "../../images/polygon/suji.png";
import tina from "../../images/polygon/tina.png";
import tommy from "../../images/polygon/tommy.png";
import zhen from "../../images/polygon/zhen.png";
import amit from "../../images/polygon/amit.jpg";
import brendan from "../../images/polygon/brendan.jpg";
import sameer from "../../images/polygon/sameer.jpg";
import alex from "../../images/polygon/alex.jpg";

const speakers = [
  {
    image: aakash,
    name: "Aakash Thumaty",
    company: ["Jump"],
    twitter: "https://mobile.twitter.com/aakashthumaty",
  },
  {
    image: alan,
    name: "Alan Lau",
    company: ["Animoca"],
    twitter: "https://mobile.twitter.com/alanlau999",
  },
  {
    image: alex,
    name: "Alex Svanevik",
    company: ["Nansen"],
    twitter: "https://twitter.com/ASvanevik",
  },
  {
    image: kao,
    name: "Alicia Kao",
    company: ["KuCoin"],
    twitter: "https://twitter.com/goaliciak",
  },
  {
    image: amit,
    name: "Amit Chaudhary",
    company: ["Polygon"],
    twitter: "https://mobile.twitter.com/amitchax",
  },
  {
    image: aniket,
    name: "Aniket Jindal",
    company: ["Biconomy"],
    twitter: "https://twitter.com/aniket_jindal08",
  },
  {
    image: loh,
    name: "Ben Loh",
    company: ["Treehouse"],
    twitter: "https://twitter.com/0xbenjiloh",
  },
  {
    image: brendan,
    name: "Brendan Farmer",
    company: ["Polygon zkEVM"],
    twitter: "https://mobile.twitter.com/_bfarmer",
  },
  {
    image: cindy,
    name: "Cindy Leow",
    company: ["Drift"],
    twitter: "https://twitter.com/cindyleowtt",
  },
  {
    image: dalip,
    name: "Dalip Tyagi",
    company: ["Polygon"],
    twitter: "https://twitter.com/dtyagi",
  },
  {
    image: schwartz,
    name: "David Schwartz",
    company: ["Polygon"],
    twitter: "https://twitter.com/davidsrz",
  },
  {
    image: deli,
    name: "Deli Gong",
    company: ["Automata"],
    twitter: "https://twitter.com/deligong",
  },
  {
    image: frank,
    name: "Frank DeGods",
    company: ["Dust Labs"],
    twitter: "https://twitter.com/frankdegods",
  },
  {
    image: dizon,
    name: "Gabby Dizon",
    company: ["Yield Guild Games"],
    twitter: "https://twitter.com/gabusch",
  },
  {
    image: gin,
    name: "Gin Takashi Mizuoka",
    company: ["Upbond"],
    twitter: "https://twitter.com/GinJapanTM",
  },
  {
    image: harsh,
    name: "Harsh Rajat",
    company: ["EPNS"],
    twitter: "https://twitter.com/harshrajat",
  },
  {
    image: jia,
    name: "Jia Yaoqi",
    company: ["AltLayer"],
    twitter: "https://mobile.twitter.com/jiayaoqi",
  },
  {
    image: julian,
    name: "Julian Koh",
    company: ["Ribbon Finance"],
    twitter: "https://twitter.com/juliankoh",
  },
  {
    image: jaynti,
    name: "Jaynti Kanani",
    company: ["Polygon"],
    twitter: "https://twitter.com/jdkanani",
  },
  {
    image: kanav,
    name: "Kanav Kariya",
    company: ["Jump"],
    twitter: "https://twitter.com/kanavkariya",
  },
  {
    image: kang,
    name: "Kang Li",
    company: ["CertiK"],
    twitter: "https://mobile.twitter.com/kang_li",
  },
  {
    image: lennix,
    name: "Lennix Lai",
    company: ["OKX"],
    twitter: "https://twitter.com/LennixOKX",
  },
  {
    image: matt,
    name: "Matt Liu",
    company: ["SynFutures"],
    twitter: "https://mobile.twitter.com/mattsynfutures1",
  },
  {
    image: mayur,
    name: "Mayur Kamat",
    company: ["Binance"],
    twitter: "https://twitter.com/5degreez",
  },
  {
    image: mudit,
    name: "Mudit Gupta",
    company: ["Polygon"],
    twitter: "https://twitter.com/Mudit__Gupta",
  },
  {
    image: nihar,
    name: "Nihar Shah",
    company: ["Jump"],
    twitter: "https://twitter.com/theshah39",
  },
  {
    image: nikhil,
    name: "Nikhil Suri",
    company: ["Jump"],
    twitter: "https://twitter.com/nsuri_",
  },
  {
    image: paul,
    name: "Paul Kim",
    company: ["XPLA"],
    twitter: "https://mobile.twitter.com/pkhr2013",
  },
  {
    image: robert,
    name: "Robert Chen",
    company: ["OtterSec"],
    twitter: "https://twitter.com/notdeghost",
  },
  {
    image: saeed,
    name: "Saeed Badreg",
    company: ["Jump"],
    twitter: "https://twitter.com/shadddowfax",
  },
  {
    image: sahil,
    name: "Sahil Sen",
    company: ["Quicknode"],
    twitter: "https://twitter.com/sensahil",
  },
  {
    image: sameer,
    name: "Sameer Chauhan",
    company: ["Polygon Labs"],
    twitter: null,
  },
  {
    image: saurabh,
    name: "Saurabh Sharma",
    company: ["Jump"],
    twitter: "https://twitter.com/zsparta",
  },
  {
    image: sandeep,
    name: "Sandeep Nailwal",
    company: ["Polygon"],
    twitter: "https://twitter.com/sandeepnailwal",
  },
  {
    image: sanket,
    name: "Sanket Shah",
    company: ["Polygon"],
    twitter: "https://twitter.com/sourcex44",
  },
  {
    image: shanav,
    name: "Shanav Mehta",
    company: ["Jump"],
    twitter: "https://twitter.com/shanav_m",
  },
  {
    image: steph,
    name: "Steph Orpilla",
    company: ["Polygon"],
    twitter: "https://twitter.com/0ceans404",
  },
  {
    image: suji,
    name: "Suji Yan",
    company: ["MASK"],
    twitter: "https://twitter.com/suji_yan",
  },
  {
    image: tina,
    name: "Tina Qi",
    company: ["Nexa"],
    twitter: "https://twitter.com/thetinaverse",
  },
  {
    image: tn,
    name: "TN",
    company: ["Pendle"],
    twitter: "https://twitter.com/tn_pendle",
  },
  {
    image: tommy,
    name: "Tommy Wee",
    company: ["Biconomy"],
    twitter: "https://twitter.com/TommyWee",
  },
  {
    image: zheng,
    name: "Zheng Leong Chua",
    company: ["Automata"],
    twitter: "https://twitter.com/melynx",
  },
  {
    image: zhen,
    name: "Zhen Yu Yong",
    company: ["Web3Auth"],
    twitter: "https://twitter.com/zenzhenyu",
  },
];

const week1 = [
  {
    date: "Sunday, Jan 29",
    event: "Welcome Dinner",
  },
  {
    date: "Monday, Jan 30",
    event: "Polygon Ecosystem Overview",
  },
  {
    date: "Tuesday, Jan 31",
    event: "zkEVM Overview",
  },
  {
    date: "Wednesday, Feb 1",
    event: "NFTs / Metaverse",
  },
  {
    date: "Thursday, Feb 2",
    event: "Gaming",
  },
  {
    date: "Friday, Feb 3",
    event: "Demo Day",
  },
  {
    date: "Feb 3 - Feb 5",
    event: "Gaming Weekend",
  },
];
const week2 = [
  {
    date: "Monday, Feb 6",
    event: "DeFi",
  },
  {
    date: "Tuesday, Feb 7",
    event: "Polygon Ecosystem Overview",
  },
  {
    date: "Wednesday, Feb 8",
    event: "Web3 Identity",
  },
  {
    date: "Thursday, Feb 9",
    event: "Scalability",
  },
  {
    date: "Friday, Feb 10",
    event: "Demo Day  /  Friday Night Closing Party",
  },
];

interface Agenda {
  date: string;
  event: string;
}

const agenda = {
  borderTop: "1px solid #4B608D",
  m: "20px auto",
  width: { xs: "calc(100% - 40px)", md: "calc(100% - 120px)" },
  position: "relative",
};

const agendaListItem = {
  display: { xs: "block", md: "flex" },
  padding: { xs: "20px", md: "30px 20px" },
  borderBottom: "1px solid #4B608D",
  borderRadius: 0,
  textAlign: { xs: "left", md: "initial" },
  width: "100%",
  "&:hover": {
    background: "#0C1F47",
  },
};

const Polygon = ({ location }: PageProps) => {
  return (
    <Layout>
      <MetaData title="Polygon @ the Pit" location={location} type="website" />
      <ArrowComponent></ArrowComponent>
      <Box
        component="span"
        sx={{
          position: "absolute",
          left: "65px",
          top: "200px",
          height: "calc(100% - 415px)",
          width: 0,
          borderLeft: "1px solid #4B608D",
          display: { xs: "none", xl: "block" },
          "&:before": {
            content: '""',
            position: "absolute",
            top: "-5px",
            left: "-8px",
            width: "15px",
            height: "5px",
            backgroundColor: "#1CE7C2",
          },
        }}
      />
      <Button
        component={OutboundLink}
        endIcon={<Arrow />}
        href="https://ofhqh7c17vq.typeform.com/polyatpit?utm_source=pit_page"
        variant="outlined"
        target="_blank"
        rel="noreferrer"
        sx={{
          backgroundColor: "#08FBCF",
          color: "#0C1F47",
          borderColor: "#08FBCF",
          position: "fixed",
          outline: 0,
          right: "-30px",
          transformOrigin: "center",
          top: 200,
          zIndex: 3,
          transform: "rotate(-90deg)",
          display: { xs: "none", md: "flex" },
          border: "1px solid #08FBCF",
        }}
      >
        Apply
      </Button>

      <Box component="div" sx={{ position: "relative" }}>
        <StaticImage
          src="../../images/polygon/bricks.png"
          alt="Jump"
          placeholder="blurred"
          layout="fixed"
          style={{
            position: "absolute",
            left: "-15px",
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: -2,
            pointerEvents: "none",
          }}
        />

        <Box
          component="div"
          sx={{
            maxWidth: 750,
            mx: "auto",
            mt: { xs: 12, md: 20 },
            mb: { xs: 6, md: 13 },
            textAlign: "center",
            zIndex: 2,
            position: "relative",
          }}
        >
          <Box
            component="img"
            sx={{ mb: 6, width: "85%", maxWidth: "566px" }}
            src={polygon}
            alt="Polygon@Jump"
            title="Polygon@Jump"
          />
          <Typography sx={{ maxWidth: 662, mx: "auto", fontSize: 16 }}>
            Polygon Labs and Jump have teamed up to bring you Polygon @ the Pit,
            the next premier accelerator. On January 30th, 2023, some of the
            greatest devs will convene in Singapore to spend two weeks building
            within the Polygon ecosystem.
          </Typography>
          <Typography sx={{ maxWidth: 662, mx: "auto", fontSize: 16, mt: 3 }}>
            Trust us. You won’t want to miss this.
          </Typography>
          <Button
            component={OutboundLink}
            href="https://ofhqh7c17vq.typeform.com/polyatpit?utm_source=pit_page"
            variant="outlined"
            endIcon={<Arrow />}
            sx={{ mt: 4 }}
            target="_blank"
            rel="noreferrer"
          >
            .Apply now
          </Button>
        </Box>
      </Box>
      <Box
        component="img"
        src={city}
        alt=""
        sx={{
          display: "block",
          width: "100%",
          mt: { xs: -5, md: -10, lg: -20 },
          transform: "scale(1.1)",
        }}
      />
      <Box component="div">
        <Box
          component="div"
          sx={{
            position: "relative",
            "&:before": {
              content: `url(${sideBrand})`,
              position: "absolute",
              left: 40,
              top: -80,
              display: { xs: "none", xl: "block" },
              zIndex: -1,
            },
          }}
        />
        <Box
          component="div"
          sx={{
            maxWidth: 1082,
            mx: "auto",
            mt: { xs: 5, md: 11 },
            mb: 1,
            display: "grid",
            backgroundColor: "#091A3D",
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
              md: "repeat(4, 1fr)",
            },
            gap: "10px",
            textAlign: "center",
          }}
        >
          <Box component="div" sx={box}>
            <Typography>
              <Box
                component="span"
                sx={{ fontWeight: "bold", color: "#1CE7C2" }}
              >
                Jan 30 - Feb 10
              </Box>
              <br /> 2023
            </Typography>
          </Box>
          <Box component="div" sx={box}>
            <Typography>
              <Box
                component="span"
                sx={{ fontWeight: "bold", color: "#1CE7C2" }}
              >
                Win USD $100k{" "}
              </Box>
              <br /> in prizes and bounties
            </Typography>
          </Box>
          <Box component="div" sx={box}>
            <Typography>
              <Box
                component="span"
                sx={{ fontWeight: "bold", color: "#1CE7C2" }}
              >
                Networking events
                <br />{" "}
              </Box>{" "}
              every Wed
            </Typography>
          </Box>
          <Box component="div" sx={box}>
            <Typography>
              <Box
                component="span"
                sx={{ fontWeight: "bold", color: "#1CE7C2" }}
              >
                Demo days
              </Box>
              <br /> every Friday{" "}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        component="div"
        sx={{
          position: "relative",
          "&:before": {
            content: `url(${sideBrand})`,
            position: "absolute",
            left: 40,
            top: 100,
            display: { xs: "none", xl: "block" },
            zIndex: -1,
          },
        }}
      />
      <Box
        component="div"
        sx={{ maxWidth: 1082, mx: "auto", position: "relative" }}
      >
        <StaticImage
          src="../../images/polygon/bg.svg"
          alt=""
          placeholder="blurred"
          layout="fixed"
          style={{
            position: "absolute",
            left: 0,
            width: "100%",
            top: 0,
            height: "100%",
            objectFit: "cover",
            backgroundPosition: "center center",
            pointerEvents: "none",
          }}
        />
        <Box
          component="div"
          sx={{
            background: "#051535",
            border: "1px solid #4B608D",
            padding: { xs: "30px", md: "80px 120px" },
            display: { md: "flex" },
            alignItems: "center",
            mb: 2,
            textAlign: { xs: "center", md: "initial" },
          }}
        >
          <Box
            component="img"
            src={polygon2}
            alt="Polygon @ Jump"
            loading="lazy"
            sx={{
              flex: "0 0 auto",
              marginRight: { md: "115px" },
              mb: { xs: 3, md: "initial" },
            }}
          />
          <Box component="div">
            <Typography
              variant="h3"
              sx={{
                mb: 3,
              }}
            >
              Calling{" "}
              <Box component="span" sx={{ color: "#1CE7C2" }}>
                all builders.
              </Box>
            </Typography>
            <Typography sx={{ fontSize: 14, lineHeight: 1.57 }}>
              {`On January 30th, 2023, some of the best crypto dev talent from all over the world will come together in Singapore to build. You’ll have access to technological resources, office hours with some of Polygon Labs and Jump Crypto’s top minds, seminars, meals, and enough kopi to keep you going through the night. Make connections, build awesome applications on Polygon, and shape the future of crypto.`}
            </Typography>
            <Button
              component={OutboundLink}
              href="https://ofhqh7c17vq.typeform.com/polyatpit?utm_source=pit_page"
              variant="outlined"
              endIcon={<Arrow />}
              sx={{ mt: 5 }}
              target="_blank"
              rel="noreferrer"
            >
              .Apply
            </Button>
          </Box>
        </Box>
      </Box>
      <Box
        component="div"
        sx={{
          position: "relative",
          "&:before": {
            content: `url(${sideBrand})`,
            position: "absolute",
            left: 40,
            top: 40,
            display: { xs: "none", xl: "block" },
            zIndex: -1,
          },
        }}
      />
      <Box
        component="div"
        sx={{
          maxWidth: 1082,
          mx: "auto",
          mt: { xs: 5, md: 14 },
          background: "#051537",
        }}
      >
        <Box component="div" sx={{ maxWidth: { sm: 430 } }}>
          <Box component="div" sx={{ ...box2, mb: 1.5 }}>
            <Location />
            <Typography sx={{ textAlign: "left" }}>
              <strong> Jump Trading</strong>
              <br />{" "}
              <Link
                href="https://goo.gl/maps/vzo1pGxbB9tqSAjXA"
                component={OutboundLink}
                target="_blank"
                rel="noreferrer"
                sx={{ "&:hover": { textDecoration: "underline" } }}
              >
                8 Marina View, Asia Square Tower 1, Singapore 018960
              </Link>
            </Typography>
          </Box>
          <Box component="div" sx={box2}>
            <Calendar />
            <Typography sx={{ textAlign: "left" }}>Open on weekends</Typography>
          </Box>
        </Box>
      </Box>
      <Box
        component="img"
        src={city2}
        alt=""
        sx={{
          display: "block",
          ml: "auto",
          width: { xs: "100%", md: "initial" },
          mt: { md: -20 },
          mr: "-15px",
        }}
      />
      <Box component="div" sx={{ position: "relative" }}>
        <StaticImage
          src="../../images/polygon/bricks.png"
          alt="Jump"
          placeholder="blurred"
          layout="fixed"
          style={{
            position: "absolute",
            left: "-15px",
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: -2,
            pointerEvents: "none",
          }}
        />
        <Box
          component="div"
          sx={{
            position: "relative",
            "&:before": {
              content: `url(${sideBrand})`,
              position: "absolute",
              left: 40,
              top: 80,
              display: { xs: "none", xl: "block" },
              zIndex: -1,
            },
          }}
        />
        <Box
          component="div"
          sx={{ maxWidth: 1082, mx: "auto", background: "#051537" }}
        >
          <Typography
            variant="h3"
            sx={{
              mb: 5,
            }}
          >
            Featured{" "}
            <Box component="span" sx={{ color: "#1CE7C2" }}>
              Speakers
            </Box>
          </Typography>
          <Box
            component="div"
            sx={{
              maxWidth: 1082,
              mx: "auto",
              mt: 3.2,
              mb: 5,
              display: "grid",
              gridTemplateColumns: {
                xs: "repeat(1, 1fr)",
                xs2: "repeat(2, 1fr)",
                sm: "repeat(3, 1fr)",
                md: "repeat(5, 1fr)",
              },
              gap: "14px",
            }}
          >
            {speakers.map((s) => (
              <Box component="div" key={s.name} sx={card}>
                <Box
                  component="img"
                  src={s.image}
                  alt={s.name}
                  sx={{
                    position: "absolute",
                    right: 0,
                    bottom: 0,
                    objectFit: "contain",
                  }}
                />
                <Box component="div">
                  <Typography variant="h6">{s.name}</Typography>
                  <Typography>
                    {s.company.map((c, index) => (
                      <Box key={index} component="span">
                        {c}
                      </Box>
                    ))}
                  </Typography>
                </Box>
                {s.twitter && (
                  <Link href={s.twitter} target="_blank" rel="noreferrer">
                    <Twitter />
                  </Link>
                )}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box component="div" sx={{ position: "relative" }}>
        <StaticImage
          src="../../images/polygon/lines.svg"
          alt="Jump"
          placeholder="blurred"
          layout="fixed"
          style={{
            position: "absolute",
            left: "-15px",
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: -2,
            pointerEvents: "none",
          }}
        />
        <Box
          component="div"
          sx={{
            maxWidth: 1082,
            mx: "auto",
            textAlign: "center",
            my: { xs: 8, md: 15 },
          }}
        >
          <Typography
            variant="h2"
            sx={{
              mb: 3,
            }}
          >
            What{" "}
            <Box component="span" sx={{ color: "#1CE7C2" }}>
              to expect
            </Box>
          </Typography>
          <Typography sx={{ fontSize: 18, mb: 3, maxWidth: 570, mx: "auto" }}>
            See how our previous event, xHack, was game-changing for Web3
            developers:
          </Typography>
          <Box
            component="div"
            sx={{
              position: "relative",
              maxWidth: 864,
              mx: "auto",
              mt: { xs: 4, md: 8 },
            }}
          >
            <Box
              component="div"
              sx={{
                padding: "56.25% 0 0 0",
                position: "relative",
                overflow: "hidden",
                border: "1px solid #4B608D",
                mb: { xs: 8, md: 17 },
              }}
            >
              <iframe
                loading="lazy"
                src="https://player.vimeo.com/video/773373430?&title=0&byline=0&portrait=0"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                frameBorder={0}
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        component="div"
        sx={{
          position: "relative",
          "&:before": {
            content: `url(${sideBrand})`,
            position: "absolute",
            left: 40,
            top: 25,
            display: { xs: "none", xl: "block" },
            zIndex: -1,
          },
        }}
      />
      <Box
        component="div"
        sx={{ maxWidth: 1082, mx: "auto", background: "#051537" }}
      >
        <Typography
          variant="h3"
          sx={{
            mb: 5,
          }}
        >
          Agenda
        </Typography>

        <Accordion expanded>
          <AccordionSummary
            sx={{
              p: { xs: "20px", md: "28px 50px" },
            }}
            expandIcon=" "
          >
            <Typography variant="h4">
              Week 1_{" "}
              <Box component="span" sx={{ color: "#1CE7C2" }}>
                Intro to the Polygon Community
              </Box>
            </Typography>
          </AccordionSummary>
          <Box component="div" sx={agenda}>
            {week1.map((agenda: Agenda, idx: number) => (
              <AccordionDetails key={agenda.date}>
                <Box component="div" sx={agendaListItem}>
                  <Typography sx={{ minWidth: { md: 300 } }}>
                    <Box
                      component="span"
                      sx={{
                        display: "inline-block",
                        opacity: 0.6,
                        color: "#4B608D",
                        marginRight: 2.5,
                      }}
                    >
                      {idx + 1}
                    </Box>
                    {agenda.date}
                  </Typography>
                  <Typography
                    sx={{ color: "#1CE7C2", paddingLeft: { xs: 3.2, md: 0 } }}
                  >
                    {agenda.event}
                  </Typography>
                </Box>
              </AccordionDetails>
            ))}
          </Box>
        </Accordion>
        <Accordion expanded>
          <AccordionSummary
            sx={{
              p: { xs: "20px", md: "28px 50px" },
            }}
            expandIcon=" "
          >
            <Typography variant="h4">
              Week 2_{" "}
              <Box component="span" sx={{ color: "#1CE7C2" }}>
                Building on Polygon / Scaling Solutions
              </Box>
            </Typography>
          </AccordionSummary>
          <Box component="div" sx={agenda}>
            {week2.map((agenda: Agenda, idx: number) => (
              <AccordionDetails key={agenda.date}>
                <Box component="div" sx={agendaListItem}>
                  <Typography sx={{ minWidth: { md: 300 } }}>
                    <Box
                      component="span"
                      sx={{
                        display: "inline-block",
                        opacity: 0.6,
                        color: "#4B608D",
                        marginRight: 2.5,
                      }}
                    >
                      {idx + 1}
                    </Box>
                    {agenda.date}
                  </Typography>
                  <Typography
                    sx={{ color: "#1CE7C2", paddingLeft: { xs: 3.2, md: 0 } }}
                  >
                    {agenda.event}
                  </Typography>
                </Box>
              </AccordionDetails>
            ))}
          </Box>
        </Accordion>
      </Box>

      <Box
        component="div"
        sx={{ maxWidth: 1082, mx: "auto", mt: { xs: 10, md: 17 } }}
      >
        <Typography
          variant="h3"
          sx={{
            mb: 3,
            textAlign: "center",
          }}
        >
          Attendees
        </Typography>
        <Typography
          sx={{
            fontSize: 18,
            mb: { xs: 5, md: 10 },
            maxWidth: 540,
            mx: "auto",
            textAlign: "center",
          }}
        >
          You'll be in good company: expect to meet some of the smartest brains
          in crypto from companies and projects you trust — so you can be sure
          to get the most out of it.
        </Typography>
        <Box
          component="div"
          sx={{
            background: "#0C1F47",
            border: "3px solid #1CE7C2",
            padding: "80px 60px",
            position: "relative",
            mb: { xs: 10, md: 20 },
          }}
        >
          <StaticImage
            src="../../images/pitbanner.jpg"
            alt="Introducing the Pit."
            placeholder="blurred"
            layout="fullWidth"
            objectFit="cover"
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
            }}
          />
          <Box
            component="img"
            src={pit}
            alt="Introducing the Pit."
            loading="lazy"
            sx={{
              position: { xs: "relative", md: "absolute" },
              right: { md: "10%" },
              top: { md: "50%" },
              transform: { md: "translateY(-50%)" },
              mb: { xs: 3, md: 0 },
            }}
          />
          <Typography variant="h3" sx={{ position: "relative", zIndex: 2 }}>
            Introducing the Pit.
          </Typography>
          <Typography
            sx={{
              maxWidth: 320,
              mt: 1.6,
              mb: 4,
              position: "relative",
              zIndex: 2,
            }}
          >
            Check out our new space where devs are changing the face of Web3.
          </Typography>
          <Button
            component={RouterLink}
            endIcon={<Arrow />}
            to="/thepit"
            variant="outlined"
            sx={{
              backgroundColor: "#08FBCF",
              color: "#0C1F47",
              borderColor: "#08FBCF",
              position: "relative",
              zIndex: 2,
            }}
          >
            .Learn More
          </Button>
        </Box>
      </Box>
    </Layout>
  );
};

export default Polygon;
